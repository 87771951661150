import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "components/login";
import Error from "components/Error";
import Dashboard from "layouts/Dashboard";
import Games from "components/Games";
import Reports from "components/Reports";
import React from "react";
import AddGames from "components/Games/AddGame";
import Editgame from "components/Games/EditGame";
import DetailGame from "components/Games/DetailGame";
import GiftCards from "components/GiftCards";
import SoftwaresAccount from "components/SoftwaresAccount";
import GamesAccounts from "components/GamesAccounts";
import AddGiftCards from "components/GiftCards/AddGame";
import EditGiftCards from "components/GiftCards/EditGame";
import AddSoftwaresAccount from "components/SoftwaresAccount/AddGame";
import EdiSoftwaresAccount from "components/SoftwaresAccount/EditGame";
import AddGamesAccounts from "components/GamesAccounts/AddGame";
import EditGamesAccounts from "components/GamesAccounts/EditGame";
import CountryGiftCards from "components/CountryGiftCards";
import AddCountry from "components/CountryGiftCards/AddCountry";
import EditCountry from "components/CountryGiftCards/EditCountry";
import Wallets from "components/Wallets";
import AddWallets from "components/Wallets/AddWallets";
import EditWallets from "components/Wallets/EditWallets";
import DetailWallets from "components/Wallets/DetailWallets";
import PrimaryChannel from "components/PrimaryChannel";
import Clients from "components/Clients";
import Blacklists from "components/Blacklists";
import Payments from "components/Payments";
import Payments180Days from "components/Payments180Days";
import Transactions from "components/Transactions";
import Orders from "components/Orders";
import OrderAgencys from "components/OrderAgencys";
import GiftHomes from "components/GiftHomes";
import FacebookManage from "components/Facebook-manage";
import Users from "components/Users";
import Youtube from "components/Youtube";
import ProductCategory from "components/ProductCategory";
import AdvertiseFee from "components/Advertise-fee";
import AddPayments from "components/Payments/AddPayments";
import EditPayments from "components/Payments/EditPayments";
import DetailPayments from "components/Payments/Withdraws";
import AddPayments180Days from "components/Payments180Days/AddPayments180Days";
import EditPayments180Days from "components/Payments180Days/EditPayments180Days";
import DetailPayments180Days from "components/Payments180Days/Withdraws";
import AddTransactions from "components/Transactions/AddTransactions";
import AddOrders from "components/Orders/AddOrders";
import DetailOrders from "components/Orders/DetailOrders";
import AddAgencys from "components/OrderAgencys/Agencys/AddAgencys";
import EditAgencys from "components/OrderAgencys/Agencys/EditAgencys";
import AddApiTelegrams from "components/OrderAgencys/ApiTelegrams/AddApiTelegrams";
import EditApiTelegrams from "components/OrderAgencys/ApiTelegrams/EditApiTelegrams";
import AddGift from "components/GiftHomes/Gift/AddGift";
import EditGift from "components/GiftHomes/Gift/EditGift";
import AddGiftCode from "components/GiftHomes/GiftCode/AddGiftCode";
import AddAdministrators from "components/Facebook-manage/Administrators/AddAdministrators";
import EditAdministrators from "components/Facebook-manage/Administrators/EditAdministrators";
import AddGroup from "components/Facebook-manage/Groups/AddGroup";
import EditGroup from "components/Facebook-manage/Groups/EditGroup";
import EditFanpage from "components/Facebook-manage/Fanpage/EditFanpage";
import AddFanpage from "components/Facebook-manage/Fanpage/AddFanpage";
import AddPrimaryChannel from "components/PrimaryChannel/AddPrimaryChannel";
import EditPrimaryChannel from "components/PrimaryChannel/EditPrimaryChannel";
import AddClients from "components/Clients/AddClients";
import EditClients from "components/Clients/EditClients";
import AddBlacklists from "components/Blacklists/AddBlacklists";
import EditBlacklists from "components/Blacklists/EditBlacklists";
import Blocks from "components/Blocks";
import AddSalesArticleNotes from "components/Facebook-manage/SalesArticleNotes/AddSalesArticleNotes";
import EditSalesArticleNotes from "components/Facebook-manage/SalesArticleNotes/EditSalesArticleNotes";
import AddUsers from "components/Users/AddUsers";
import EditUsers from "components/Users/EditUsers";
import AddYoutube from "components/Youtube/AddYoutube";
import Edityoutube from "components/Youtube/Edityoutube";
import AddProductCategory from "components/ProductCategory/AddProductCategory";
import EditProductCategory from "components/ProductCategory/EditProductCategory";
import AddAdvertiseFee from "components/Advertise-fee/AddAdvertiseFee";
import DetailGiftcode from "components/DetailGiftcode";
import Profiles from "components/Profiles";
import SkinItemGeneral from "components/SkinItemGeneral";
import AddSkinItemGeneral from "components/SkinItemGeneral/AddSkinItemGeneral";
import EditSkinItemGeneral from "components/SkinItemGeneral/EditSkinItemGeneral";
import DetailSkinItemGeneral from "components/SkinItemGeneral/DetailSkinItemGeneral";
import FriendsGameId from "components/FriendsGameId";
import AddFriendsGameId from "components/FriendsGameId/AddFriendsGameId";
import EditFriendsGameId from "components/FriendsGameId/EditFriendsGameId";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route path="/detail-giftcode/:id" element={<DetailGiftcode />} />
          <Route path="*" element={<Error />} />
          <Route path="/" element={<Dashboard />}>
            <Route path="/app/reports" element={<Reports />} />
            <Route path="/app/games" element={<Games />} />
            <Route path="/app/giftcards" element={<GiftCards />} />
            <Route path="/app/soft-ware" element={<SoftwaresAccount />} />
            <Route path="/app/games-account" element={<GamesAccounts />} />
            <Route
              path="/app/skin-item-general"
              element={<SkinItemGeneral />}
            />
            <Route path="/app/friends-game-id" element={<FriendsGameId />} />
            <Route path="/app/countrygiftcard" element={<CountryGiftCards />} />
            <Route path="/app/wallets" element={<Wallets />} />
            <Route path="/app/primary-channel" element={<PrimaryChannel />} />
            <Route path="/app/clients" element={<Clients />} />
            <Route path="/app/blocks" element={<Blocks />} />
            <Route path="/app/blacklists" element={<Blacklists />} />
            <Route path="/app/payments" element={<Payments />} />
            <Route path="/app/paypal-180-days" element={<Payments180Days />} />
            <Route path="/app/transactions" element={<Transactions />} />
            <Route path="/app/orders" element={<Orders />} />
            <Route path="/app/order-agencys" element={<OrderAgencys />} />
            <Route path="/app/gift-home" element={<GiftHomes />} />
            <Route path="/app/facebook-manage" element={<FacebookManage />} />
            <Route path="/app/users" element={<Users />} />
            <Route path="/app/client-youtube" element={<Youtube />} />
            <Route path="/app/product-category" element={<ProductCategory />} />
            <Route path="/app/advertise-fee" element={<AdvertiseFee />} />
            <Route path="/app/profiles/:id" element={<Profiles />} />

            <Route path="/app/games/add" element={<AddGames />} />
            <Route path="/app/games/edit/:id" element={<Editgame />} />
            <Route path="/app/games/:id" element={<DetailGame />} />
            <Route path="/app/games-account/:id" element={<DetailGame />} />
            <Route path="/app/soft-ware/:id" element={<DetailGame />} />
            <Route path="/app/giftcards/:id" element={<DetailGame />} />
            <Route
              path="/app/skin-item-general/add"
              element={<AddSkinItemGeneral />}
            />
            <Route
              path="/app/skin-item-general/edit/:id"
              element={<EditSkinItemGeneral />}
            />
            <Route
              path="/app/skin-item-general/:id"
              element={<DetailSkinItemGeneral />}
            />
            <Route
              path="/app/friends-game-id/add"
              element={<AddFriendsGameId />}
            />
            <Route
              path="/app/friends-game-id/edit/:id"
              element={<EditFriendsGameId />}
            />
            <Route path="/app/wallets/add" element={<AddWallets />} />
            <Route path="/app/wallets/edit/:id" element={<EditWallets />} />
            <Route path="/app/wallets/:id" element={<DetailWallets />} />
            <Route
              path="/app/primary-channel/add"
              element={<AddPrimaryChannel />}
            />
            <Route
              path="/app/primary-channel/edit/:id"
              element={<EditPrimaryChannel />}
            />
            <Route path="/app/clients/add" element={<AddClients />} />
            <Route path="/app/clients/edit/:id" element={<EditClients />} />
            <Route path="/app/blacklists/add" element={<AddBlacklists />} />
            <Route
              path="/app/blacklists/edit/:id"
              element={<EditBlacklists />}
            />
            <Route path="/app/payments/add" element={<AddPayments />} />
            <Route path="/app/payments/edit/:id" element={<EditPayments />} />
            <Route path="/app/payments/:id" element={<DetailPayments />} />
            <Route path="/app/transactions/add" element={<AddTransactions />} />
            <Route path="/app/orders/add" element={<AddOrders />} />
            <Route path="/app/orders/:id" element={<DetailOrders />} />
            <Route path="/app/order-agencys/add" element={<AddAgencys />} />
            <Route path="/app/gift-home/gift/add" element={<AddGift />} />
            <Route path="/app/gift-home/gift/edit/:id" element={<EditGift />} />
            <Route
              path="/app/gift-home/gift-code/add"
              element={<AddGiftCode />}
            />
            <Route
              path="/app/order-agencys/edit/:id"
              element={<EditAgencys />}
            />
            <Route
              path="/app/order-agencys/api-telegrams/add"
              element={<AddApiTelegrams />}
            />
            <Route
              path="/app/order-agencys/api-telegrams/edit/:id"
              element={<EditApiTelegrams />}
            />
            <Route
              path="/app/paypal-180-days/add"
              element={<AddPayments180Days />}
            />
            <Route
              path="/app/paypal-180-days/edit/:id"
              element={<EditPayments180Days />}
            />
            <Route
              path="/app/paypal-180-days/:id"
              element={<DetailPayments180Days />}
            />

            <Route path="/app/giftcards/add" element={<AddGiftCards />} />
            <Route path="/app/giftcards/edit/:id" element={<EditGiftCards />} />
            <Route
              path="/app/soft-ware/add"
              element={<AddSoftwaresAccount />}
            />
            <Route
              path="/app/soft-ware/edit/:id"
              element={<EdiSoftwaresAccount />}
            />
            <Route
              path="/app/games-account/add"
              element={<AddGamesAccounts />}
            />
            <Route
              path="/app/games-account/edit/:id"
              element={<EditGamesAccounts />}
            />
            <Route path="/app/countrygiftcard/add" element={<AddCountry />} />
            <Route
              path="/app/countrygiftcard/edit/:id"
              element={<EditCountry />}
            />

            <Route
              path="/app/facebook-manage/administrators/add"
              element={<AddAdministrators />}
            />
            <Route
              path="/app/facebook-manage/administrators/edit/:id"
              element={<EditAdministrators />}
            />
            <Route
              path="/app/facebook-manage/groups/add"
              element={<AddGroup />}
            />
            <Route
              path="/app/facebook-manage/groups/edit/:id"
              element={<EditGroup />}
            />
            <Route
              path="/app/facebook-manage/fanpages/add"
              element={<AddFanpage />}
            />
            <Route
              path="/app/facebook-manage/fanpages/edit/:id"
              element={<EditFanpage />}
            />
            <Route
              path="/app/facebook-manage/sales-article-notes/add"
              element={<AddSalesArticleNotes />}
            />
            <Route
              path="/app/facebook-manage/sales-article-notes/edit/:id"
              element={<EditSalesArticleNotes />}
            />
            <Route path="/app/users/add" element={<AddUsers />} />
            <Route path="/app/users/edit/:id" element={<EditUsers />} />
            <Route path="/app/client-youtube/add" element={<AddYoutube />} />
            <Route
              path="/app/client-youtube/edit/:id"
              element={<Edityoutube />}
            />
            <Route
              path="/app/product-category/add"
              element={<AddProductCategory />}
            />
            <Route
              path="/app/product-category/edit/:id"
              element={<EditProductCategory />}
            />
            <Route
              path="/app/advertise-fee/add"
              element={<AddAdvertiseFee />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
